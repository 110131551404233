import { styled } from '@mui/system';

import { createTheme, AccordionSummary } from '@mui/material';



const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
            contrastText: '#fff',
            dark: '#1565c0',
        },
        secondary: {
            main: '#ea660e',
            contrastText: '#fff',
            dark: '#ea660e',
        },
    },
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    '&:hover': {
        background: theme.palette.primary.dark,
    },

    '&.Mui-expanded': {
        background: theme.palette.primary.dark,
    },

    '&:before': {
        display: 'none',
    },
}));


export { theme, StyledAccordionSummary };