export const actionTypes = {
    SET_ERROR: Symbol(),
    SET_HEIGHT: Symbol(),
    SET_LOADING: Symbol(),
    SET_COLUMNS: Symbol(),
    SET_FILTERS: Symbol(),
    SET_SEARCH_FILTERS: Symbol(),
    SET_ROWS: Symbol(),
    SET_ORDER_BY: Symbol(),
    SET_ORDER: Symbol(),
    SET_TOTAL_PAGES: Symbol(),
    SET_TOTAL_REGS: Symbol(),
    SET_PAGE: Symbol(),
    SET_ROWS_PER_PAGE: Symbol(),
    SET_LAST_SEARCH: Symbol(),
    EMPTY: Symbol(),
    SET_FULL_ROWS: Symbol(),
};