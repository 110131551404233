import axios from 'axios';

export const getDatabases = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getDatabases`);

        if (response.data.error) {
            throw new Error(response.data.message);
        } else {
            return response.data;
        }
    } catch (error) {
        if (error.response?.data?.error) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Error fetching tables: ' + error);
    }
};
