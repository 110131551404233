import React from 'react';

import {
    Button,
    Box,
    TextField,
    Accordion,
    AccordionDetails
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { StyledAccordionSummary } from '../../themes/default-theme.js';


import Loading from '../Loading';


const AccordionFilters = ({ error, loading, columns, register, handleSubmit, onSubmit, handleClearFilters }) => {


    return (
        <Box>
            <div>
                {
                    loading ? (
                        <Loading />
                    ) : error ? (
                        <div>{error}</div>
                    ) : (
                        columns ? (
                            <div>
                                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                    <Accordion>
                                        <StyledAccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            Filtros
                                        </StyledAccordionSummary>
                                        <AccordionDetails>
                                            {columns.map((column) => (
                                                <TextField
                                                    name={column.column_name}
                                                    key={column.column_name}
                                                    label={column.label ? column.label : column.column_name}
                                                    {...register(column.column_name)}
                                                />
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>

                                    <section>
                                        <Button onClick={handleClearFilters} disabled={loading} color="primary">Limpiar filtros</Button>
                                        <Button type="submit" disabled={loading} color="secondary">Buscar</Button>
                                    </section>
                                </form>
                            </div>
                        ) : null
                    )
                }
            </div>
        </Box>

    );
};

export default AccordionFilters;