
import { useEffect } from 'react';

import { useFilters } from '../hooks/useFilters';
import { useGridView } from '../hooks/useGridView';



import { getError } from '../constants/errorTypes';


import { getDatabases } from '../services/getDatabases';

import { filterArray, sortLocal } from '../utils/commonFunctions';

const useDatabases = () => {


    const Filters = useFilters();
    const GridViewST = useGridView();


    useEffect(() => {

        let columns = [
            { 'column_name': 'schema_name', 'label': 'Base de Datos', 'navigate': 'table-list-by-schema/', data_type: 'varchar' },
            { 'column_name': 'totalTablas', 'label': 'Tablas', data_type: 'int' },
        ]

        GridViewST.callAction('SET_COLUMNS', columns);
        Filters.callAction('SET_COLUMNS', columns);

        const getList = async () => {
            GridViewST.callAction('SET_ERROR', '');
            
            GridViewST.callAction('SET_LOADING', true);
            try {
                const result = await getDatabases();

                if (result.list.length === 0) throw new Error('EMPTY_LIST')
                GridViewST.callAction('SET_FULL_ROWS', result.list);
                GridViewST.callAction('SET_ROWS', result.list);
            } catch (e) {
                GridViewST.callAction('SET_ERROR', getError(e));
            } finally {
                GridViewST.callAction('SET_LOADING', false);
            }
        };
        getList();
    }, []);

    useEffect(() => {

        GridViewST.callAction('SET_LOADING', true);
        try {
            GridViewST.callAction('SET_ROWS', filterArray(GridViewST.fullRows, Filters.filters));
        } catch (e) {
            GridViewST.callAction('SET_ERROR', getError(e));
        } finally {
            GridViewST.callAction('SET_LOADING', false);
        }
    }, [Filters.filters]);


    useEffect(() => {
        GridViewST.callAction('SET_LOADING', true);
        try {
            GridViewST.callAction('SET_ROWS', sortLocal(GridViewST.fullRows, GridViewST.orderBy, GridViewST.order === 'asc'));
        } catch (e) {
            GridViewST.callAction('SET_ERROR', getError(e));
        } finally {
            GridViewST.callAction('SET_LOADING', false);
        }
    }, [GridViewST.orderBy, GridViewST.order]);


    return {
        Filters,
        GridViewST,
    }
};

export default useDatabases;
