import React from 'react';
import { Box, Stack, Typography, FormControl, MenuItem, Select, Grid } from '@mui/material';

import CustomPagination from '../CustomPagination';

const rowsPerPageOptions = [10, 25, 50, 100];


const PaginationWithPageSize = ({ error, loading, rowsPerPage, onRowsPerPageChange, totalPages, page, handlePageChange }) => {
    const handleRowsPerPageChange = (event) => {
        onRowsPerPageChange(event.target.value);
    };
    return (
        !error ?
            (
                <Box style={{ marginTop: 15 }}>
                    <Stack spacing={2}>
                        <FormControl>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <CustomPagination
                                        variant="outlined"
                                        shape="rounded"
                                        color="primary"
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        disabled={loading}
                                        size="large"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="subtitle1" mr={2}>
                                            Registros por página:
                                        </Typography>
                                        <Select
                                            labelId="rows-per-page-label"
                                            id="rows-per-page"
                                            value={rowsPerPage}
                                            onChange={handleRowsPerPageChange}
                                            disabled={loading}
                                            size="large"
                                        >
                                            {rowsPerPageOptions.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Stack >
                </Box >
            ) : error
    );
};

export default PaginationWithPageSize;
