import { useReducer, useEffect } from 'react';

import { actionTypes } from '../constants/actionTypes';

import { GridViewReducer, initialState } from '../reducers/GridViewReducer';


export function useGridView() {

    const [state, dispatch] = useReducer(GridViewReducer, initialState)

    const callAction = (type, payload) => {
        dispatch({ type: actionTypes[type], payload: payload });
    }

    const handleSortRequest = (property) => {
        const isAsc = state.orderBy === property && state.order === 'asc';
        callAction('SET_ORDER', isAsc ? 'desc' : 'asc');
        callAction('SET_ORDER_BY', property);
    };


    useEffect(() => {
        const filterRows = () => {
            return state.rows.filter((row) => {
                // Verifica si todos los filtros coinciden con su columna correspondiente
                return Object.entries(state.filters).every(([column_name, filterValue]) => {
                    // Compara el valor de la columna en minúsculas con el valor del filtro en minúsculas
                    return row[column_name]
                        .toString()
                        .toLowerCase()
                        .includes(filterValue.toLowerCase());
                });
            });
        }
        callAction('SET_ROWS', filterRows());
    }, [state.filters]);

    return {
        ...state,
        callAction,
        handleSortRequest
    };
}