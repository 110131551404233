import { useEffect } from 'react';



import { useFilters } from './useFilters';
import { useGridView } from './useGridView';


import { getTables } from '../services/getTables';


import { filterArray, sortLocal } from '../utils/commonFunctions';

import { getError } from '../constants/errorTypes';


export default function useTableListBySchema({ tableSchema }) {
    const Filters = useFilters();
    const GridViewST = useGridView();

    useEffect(() => {
        GridViewST.callAction('SET_LOADING', true);

        const getList = async () => {
            try {
                let response = await getTables(tableSchema);

                if (response.list.length === 0) throw new Error('EMPTY_LIST')

                let columns = [
                    { column_name: 'table_name', 'label': 'Tabla', data_type: 'varchar', navigate: 'get-data-list/' + tableSchema + '/' },
                    { column_name: 'table_rows', 'label': 'Registros', data_type: 'int' },
                ];

                Filters.callAction('SET_COLUMNS', columns);

                GridViewST.callAction('SET_COLUMNS', columns);
                GridViewST.callAction('SET_FULL_ROWS', response.list);
                GridViewST.callAction('SET_ROWS', response.list);
            } catch (e) {

                GridViewST.callAction('SET_ROWS', []);

                GridViewST.callAction('SET_ERROR', getError(e));
                Filters.callAction('SET_ERROR', getError(e));
            } finally {
                GridViewST.callAction('SET_LOADING', false);
            }
        };
        getList();
    }, [tableSchema]);

    useEffect(() => {

        GridViewST.callAction('SET_LOADING', true);
        try {
            GridViewST.callAction('SET_ROWS', filterArray(GridViewST.fullRows, Filters.filters));
        } catch (e) {
            console.log(e, '<--- e');
            GridViewST.callAction('SET_ERROR', getError(e));
        } finally {
            GridViewST.callAction('SET_LOADING', false);
        }
    }, [Filters.filters]);

    useEffect(() => {
        GridViewST.callAction('SET_LOADING', true);
        try {
            GridViewST.callAction('SET_ROWS', sortLocal(GridViewST.fullRows, GridViewST.orderBy, GridViewST.order === 'asc'));
        } catch (e) {
            console.log(e, '<--- e');
            GridViewST.callAction('SET_ERROR', getError(e));
        } finally {
            GridViewST.callAction('SET_LOADING', false);
        }
    }, [GridViewST.orderBy, GridViewST.order]);

    return {
        Filters,
        GridViewST
    };
}