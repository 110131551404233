import { useReducer, useEffect } from 'react';



import { actionTypes } from '../constants/actionTypes';


import { ScreenReducer, initialState } from '../reducers/ScreenReducer';


export function useScreen() {

    const [state, dispatch] = useReducer(ScreenReducer, initialState)


    useEffect(() => {
        const handleResize = () => {
            dispatch({ type: actionTypes.SET_HEIGHT, payload: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);

        // Limpiar el evento 'resize' cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    return {
        ...state,
    };
}