import { useReducer } from 'react';


import { FiltersReducer, initialState } from '../reducers/FiltersReducer';

import { actionTypes } from '../constants/actionTypes';


import { useForm } from 'react-hook-form';

export function useFilters({ Paginator } = {}) {


  const { register, handleSubmit, reset } = useForm();

  const [state, dispatch] = useReducer(FiltersReducer, initialState);

  const callAction = (type, payload) => {
    dispatch({ type: actionTypes[type], payload: payload });
  }

  const handleClearFilters = () => {

    callAction('SET_FILTERS', {});
    reset({});

    if (Paginator) {
      Paginator.callAction('SET_PAGE', 1);
    }
  };

  const onSubmit = (data) => {

    const filters = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== '') {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    callAction('SET_FILTERS', filters);

    if (Paginator) {
      Paginator.callAction('SET_PAGE', 1);
    }
  };

  return {
    ...state,
    callAction,
    register,
    handleSubmit,
    onSubmit,
    handleClearFilters
  };
}