function HomeComponent() {

    return (
        <div>
            Esto es un Inicio
        </div>
    );
}

export default HomeComponent;
