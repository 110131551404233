
import { useEffect } from 'react';


import { useRouteParams } from '../providers/RouteParamsProvider';


import { useScreen } from './useScreen';
import { useFilters } from './useFilters';
import { useGridView } from './useGridView';
import { usePaginator } from './usePaginator';


import { searchResults } from '../services/searchResults';
import { columnsList } from '../services/columnsList.js';



import { getError } from '../constants/errorTypes';


const useDataList = () => {

    const { tableSchema, tableName } = useRouteParams();

    //const { tableSchema, tableName } = useRouteParams();

    const Screen = useScreen();
    const Paginator = usePaginator();
    const Filters = useFilters({ Paginator });
    const GridViewST = useGridView();
    /*
        useEffect(() => {
            console.log(Screen, '<--- Screen.height useEffect')
        }, [Screen.height]);*/

    useEffect(() => {

        const getColumns = async () => {
            if (typeof tableSchema === 'undefined') return;
            if (typeof tableName === 'undefined') return;
            Filters.callAction('SET_ERROR', null);

            Filters.callAction('SET_LOADING', true);
            try {
                const result = await columnsList({ tableSchema, tableName });

                if (result.list.length === 0) throw new Error('MISSING_TABLE')
                Filters.callAction('SET_COLUMNS', result.list);
                GridViewST.callAction('SET_COLUMNS', result.list);
            } catch (e) {
                Filters.callAction('SET_ERROR', getError(e));
            } finally {
                Filters.callAction('SET_LOADING', false);
            }
        };
        getColumns();
    }, [tableSchema, tableName]);


    useEffect(() => {

        const getList = async () => {
            GridViewST.callAction('SET_ERROR', '');
            const searchOptions = {
                tableSchema,
                tableName,
                filters: Filters.filters,
                order: GridViewST.order,
                orderBy: GridViewST.orderBy,
                pageNumber: Paginator.page,
                pageSize: Paginator.rowsPerPage
            };
            if (JSON.stringify(Filters.lastSearch) === JSON.stringify(searchOptions)) return;


            GridViewST.callAction('SET_LOADING', true);
            try {
                const result = await searchResults(searchOptions);

                if (result.list.length === 0) throw new Error('EMPTY_LIST')
                GridViewST.callAction('SET_ROWS', result.list);
                Paginator.callAction('SET_TOTAL_PAGES', result.totalPages);
                Filters.callAction('SET_LAST_SEARCH', searchOptions);
            } catch (e) {
                console.log(e,'<--- llego ptm');
                GridViewST.callAction('SET_ERROR', getError(e));
                Paginator.callAction('SET_ERROR', getError(e));
            } finally {
                GridViewST.callAction('SET_LOADING', false);
            }
        };
        getList();
    }, [tableSchema, tableName, Filters.filters, GridViewST.orderBy, GridViewST.order, Paginator.page, Paginator.rowsPerPage]);


    return {
        Screen,
        Filters,
        GridViewST,
        Paginator
    }
};

export default useDataList;
