import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const CustomPagination = ({ disabled, ...props }) => {

    const renderItem = (itemProps) => {
        disabled = disabled || props.count <= 1;
        return <PaginationItem {...itemProps} disabled={disabled} />;
    };

    return <Pagination {...props} renderItem={renderItem} />;
};

export default CustomPagination;
