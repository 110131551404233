import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TableListBySchema from './pages/TableListBySchema';
import DataList from './pages/DataList';
import Databases from './pages/Databases';

import { RouteParamsProvider } from './providers/RouteParamsProvider';
import './App.css';

import { ThemeProvider } from '@mui/material';
import { MessageModalProvider } from './providers/MessageModalProvider';

import { theme } from './themes/default-theme';
import MainComponent from './components/Main';
import HomeComponent from './components/Home';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>

          <MessageModalProvider>

            <MainComponent />
            <div className="contentComponents">
              <Routes>
                <Route path="/" element={<RouteParamsProvider><HomeComponent /></RouteParamsProvider>} />
                <Route path="/table-list-by-schema/:tableSchema" element={<RouteParamsProvider><TableListBySchema /></RouteParamsProvider>} />
                <Route path="/get-data-list/:tableSchema/:tableName" element={<RouteParamsProvider>
                  <DataList />
                </RouteParamsProvider>} />
                <Route path="/get-databases" element={<RouteParamsProvider>
                  <Databases />
                </RouteParamsProvider>} />
              </Routes>
            </div>
          </MessageModalProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div >
  );
}

export default App;
