export const initialState = {
    error: null,
    loading: false,
    columns: [],
    filters: {},
    searchFilters: {},
    lastSearch: {},
};


import { actionTypes } from '../constants/actionTypes';

export function FiltersReducer(state, action) {
    switch (action.type) {
        case actionTypes.SET_ERROR:
            return { ...state, error: action.payload };
        case actionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case actionTypes.SET_COLUMNS:
            return { ...state, columns: action.payload };
        case actionTypes.SET_FILTERS:
            return { ...state, filters: action.payload, page: 1 };
        case actionTypes.SET_SEARCH_FILTERS:
            return { ...state, searchFilters: action.payload };
        case actionTypes.SET_LAST_SEARCH:
            return { ...state, lastSearch: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
