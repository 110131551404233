import axios from 'axios';

export const searchResults = async ({
    tableSchema,
    tableName,
    filters = [],
    pageNumber = 1,
    pageSize = 10,
    orderBy = 'id',
    order = 'ASC'
}) => {
    if (!tableSchema) throw new Error('TABLE_SCHEMA_REQUIRED');
    if (!tableName) throw new Error('TABLE_NAME_REQUIRED');

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getDataRow`, {
            tableSchema,
            tableName,
            filters,
            pageNumber,
            pageSize,
            orderBy,
            desc: order === 'desc'
        });

        if (response.data.error) {
            throw new Error(response.data.message);
        } else {
            return response.data;
        }
    } catch (error) {
        if (error.response?.data?.error) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Error fetching tables: ' + error);
    }
};
