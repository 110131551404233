import axios from 'axios';

export const columnsList = async ({ tableSchema, tableName }) => {

    if (!tableSchema) throw new Error('TABLE_SCHEMA_REQUIRED');
    if (!tableName) throw new Error('TABLE_NAME_REQUIRED');

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getColumns?tableSchema=${tableSchema}&tableName=${tableName}`);
        
        if (response.data.error) {
            throw new Error(response.data.message);
        } else {
            return response.data;
        }
    } catch (error) {
        if (error.response?.data?.error) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Error fetching tables: ' + error);
    }
};
