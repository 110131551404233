import React from 'react';

import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
} from '@mui/material';

import CustomLink from '../CustomLink';
import Loading from '../Loading';

import { dataFormat } from '../../utils/commonFunctions';


export default function GridView({ error, loading, columns, rows, windowHeight = 800, orderBy, order, handleSortRequest }) {

    const tableHeight = windowHeight - 250;

    return (
        loading ? (
            <Loading minHeight={tableHeight} />
        ) :

            error ? (
                <div>{error}</div>
            ) : (
                rows && rows.length > 0 ? (
                    <Box>
                        <TableContainer style={{ height: tableHeight }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column) => (

                                                <TableCell component="th" className='cell' key={column.column_name}>
                                                    {handleSortRequest ?
                                                        (
                                                            <TableSortLabel
                                                                active={orderBy === column.column_name}
                                                                direction={orderBy === column.column_name ? order : 'asc'}
                                                                onClick={() => handleSortRequest(column.column_name)}
                                                            >
                                                                {column.column_name}
                                                            </TableSortLabel>
                                                        )
                                                        : (
                                                            column.label ? column.label : column.column_name
                                                        )}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id ? row.id : Math.random()}>
                                            {columns.map((column) => (
                                                column.navigate ? (
                                                    <TableCell
                                                        className='cell'
                                                        key={column.column_name}>
                                                        <CustomLink className="tdLink" to={`/${column.navigate}${row[column.column_name]}`}>{row[column.column_name]}</CustomLink>
                                                    </TableCell>
                                                ) : (
                                                    < TableCell className='cell' key={column.column_name}> {dataFormat(row[column.column_name], column.data_type)}</TableCell>
                                                )
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box >
                ) : null
            )
    );
}