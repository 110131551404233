import { useReducer } from 'react';

import { PaginatorReducer, initialState } from '../reducers/PaginatorReducer';

import { actionTypes } from '../constants/actionTypes';

export function usePaginator() {

    const [state, dispatch] = useReducer(PaginatorReducer, initialState)

    const callAction = (type, payload) => {
        dispatch({ type: actionTypes[type], payload: payload });
    }
    
    const handlePageChange = (event, value) => {
        callAction('SET_PAGE', parseInt(value));
    };

    const onRowsPerPageChange = (value) => {
        callAction('SET_ROWS_PER_PAGE', parseInt(value));
        callAction('SET_PAGE', 1);
    };
    return {
        ...state,
        callAction,
        handlePageChange,
        onRowsPerPageChange
    };
}