import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

const RouteParamsContext = createContext(null);

export const useRouteParams = () => {
  const context = useContext(RouteParamsContext);
  return context || {};
};

export const RouteParamsProvider = ({ children }) => {
  const params = useParams();
  return (
    <RouteParamsContext.Provider value={params}>
      {children}
    </RouteParamsContext.Provider>
  );
};
