export const errorTypes = {
    EMPTY_LIST: Symbol('No se encontraron resultados'),
    MISSING_TABLE: Symbol('Tabla no encontrada'),
    MISSING_COLUMNS: Symbol('Columnas no definidas'),
    TABLE_NAME_REQUIRED: Symbol('No se ha especificado el nombre de la tabla'),
    TABLE_SCHEMA_REQUIRED: Symbol('No se ha especificado el esquema'),
    
};

export function getError(error, defaultError = 'Ha ocurrido un error') {
    if(typeof errorTypes[error.message] === 'undefined') return defaultError;

    return String(errorTypes[error.message]).slice(7,-1);
}