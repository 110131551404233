import {
    Box,
} from '@mui/material';


import GridView from '../../components/GridView';

import useDatabases from '../../hooks/useDatabases';

import AccordionFilters from '../../components/AccordionFilters';

const Databases = () => {

    const { GridViewST, Filters } = useDatabases();

    const propsGridView = {
        error: GridViewST.error,
        loading: GridViewST.loading,
        columns: GridViewST.columns,
        rows: GridViewST.rows,
        handleSortRequest: GridViewST.handleSortRequest,
    }

    const propsAccordionFilters = {
        error: Filters.error,
        loading: Filters.loading,
        columns: Filters.columns,
        register: Filters.register,
        handleSubmit: Filters.handleSubmit,
        onSubmit: Filters.onSubmit,
        handleClearFilters: Filters.handleClearFilters
    };

    return (
        <Box>
            <AccordionFilters
                {...propsAccordionFilters}
            />
            {/* Sección de listado */}
            <GridView
                {...propsGridView}
            />
        </Box>

    );
};

export default Databases;
