

import useDataList from '../../hooks/useDataList';

import {
    Box
} from '@mui/material';


import AccordionFilters from '../../components/AccordionFilters';
import GridView from '../../components/GridView';
import PaginationWithPageSize from '../../components/PaginationWithPageSize';

const DataList = () => {

    const {
        Screen,
        Filters,
        GridViewST,
        Paginator
    } = useDataList();



    const propsAccordionFilters = {
        error: Filters.error,
        loading: Filters.loading,
        columns: Filters.columns,
        register: Filters.register,
        handleSubmit: Filters.handleSubmit,
        onSubmit: Filters.onSubmit,
        handleClearFilters: Filters.handleClearFilters
    };

    const propsGridView = {
        error: GridViewST.error,
        loading: GridViewST.loading,
        columns: GridViewST.columns,
        rows: GridViewST.rows,
        windowHeight: Screen.height,
        orderBy: GridViewST.orderBy,
        order: GridViewST.order,
        handleSortRequest: GridViewST.handleSortRequest
    };


    const propsPagination = {
        error: Paginator.error,
        loading: Paginator.loading,
        rowsPerPage: Paginator.rowsPerPage,
        onRowsPerPageChange: Paginator.onRowsPerPageChange,
        totalPages: Paginator.totalPages,
        page: Paginator.page,
        handlePageChange: Paginator.handlePageChange
    }

    return (
        <Box>
            {/* Sección de filtros */}
            < AccordionFilters
                {...propsAccordionFilters}
            />

            {/* Sección de listado */}
            < GridView
                {...propsGridView}
            />

            {/* Paginación */}
            <PaginationWithPageSize
                {...propsPagination}
            />
        </Box >

    );

};

export default DataList;
