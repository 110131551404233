export const initialState = {
    height: window.innerHeight,
};


import { actionTypes } from '../constants/actionTypes';

export function ScreenReducer(state, action) {
    switch (action.type) {
        case actionTypes.SET_HEIGHT:
            return { ...state, height: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
