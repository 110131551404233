import { React } from 'react';

import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import { useNavigate } from 'react-router-dom';

function MainComponent() {
    
    const navigate = useNavigate();

    return (
        <AppBar className="menu" position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="home" onClick={() => navigate(`/`)}>
                    <HomeIcon />
                </IconButton>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Mi aplicación
                </Typography>
                <Button color="inherit"  onClick={() => navigate(`/`)}>
                    Inicio
                </Button>
                <Button color="inherit"  onClick={() => navigate(`/get-databases`)}>
                    Bases de datos
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default MainComponent;
