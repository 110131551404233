import {
    Box,
} from '@mui/material';

import { useRouteParams } from '../../providers/RouteParamsProvider';


import AccordionFilters from '../../components/AccordionFilters';
import GridView from '../../components/GridView';


import useTableListBySchema from '../../hooks/useTableListBySchema';


function TableListBySchema() {

    const { tableSchema } = useRouteParams();

    const { Filters, GridViewST } = useTableListBySchema({ tableSchema });

    const propsAccordionFilters = {
        error: Filters.error,
        loading: Filters.loading,
        columns: Filters.columns,
        register: Filters.register,
        handleSubmit: Filters.handleSubmit,
        onSubmit: Filters.onSubmit,
        handleClearFilters: Filters.handleClearFilters
    };

    const propsGridView = {
        error: GridViewST.error,
        loading: GridViewST.loading,
        columns: GridViewST.columns,
        rows: GridViewST.rows,
        handleSortRequest: GridViewST.handleSortRequest
    }

    return (
        <div>
            <h3>Tablas en la base de datos: {tableSchema}</h3>

            <Box>
                {/* Sección de listado */}
                <AccordionFilters
                    {...propsAccordionFilters}
                />

                <GridView
                    {...propsGridView}
                />
            </Box>

        </div>
    );
}

export default TableListBySchema;
