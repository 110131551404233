import {
    Box,
    CircularProgress
} from '@mui/material';


export default function Loading({ props }) {

    return (
        <Box display="flex" justifyContent="center" alignItems="center" {...props}>
            <CircularProgress />
        </Box>
    )
}