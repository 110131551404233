export const initialState = {
    error: '',
    loading: false,
    page: 1,
    totalPages: 0,
    rowsPerPage: 10,
};
import { actionTypes } from '../constants/actionTypes';

export function PaginatorReducer(state, action) {
    switch (action.type) {
        case actionTypes.SET_ERROR:
            return { ...state, error: action.payload };
        case actionTypes.SET_LOADING:
            return { ...state, loading: action.payload };
        case actionTypes.SET_PAGE:
            return { ...state, page: action.payload };
        case actionTypes.SET_TOTAL_PAGES:
            return { ...state, totalPages: action.payload };
        case actionTypes.SET_ROWS_PER_PAGE:
            return { ...state, rowsPerPage: action.payload };        
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}
