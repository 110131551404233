import { useState, createContext } from 'react';

const MessageModalContext = createContext();

export const MessageModalProvider = ({ children }) => {

  const [messageModal, setMessageModal] = useState({
    open: false,
    message: '',
    title: '',
  });

  const openMessageModal = (message, title) => {
    setMessageModal({
      open: true,
      message,
      title,
    });
  };

  const closeMessageModal = () => {
    setMessageModal({
      ...messageModal,
      open: false,
    });
  };

  return (
    <MessageModalContext.Provider
      value={{ messageModal, openMessageModal, closeMessageModal }}
    >
      {children}
    </MessageModalContext.Provider>
  );
};

export default { MessageModalProvider, MessageModalContext };