function pad(num) {
    return num.toString().padStart(2, '0');
}

export function dateFormat(fechaISO) {
    const fechaObj = new Date(fechaISO);

    const anio = fechaObj.getFullYear();
    const mes = pad(fechaObj.getMonth() + 1); // Los meses van de 0 a 11, así que agregamos 1.
    const dia = pad(fechaObj.getDate());

    return `${anio}-${mes}-${dia}`;
}


export function dateTimeFormat(fechaISO) {
    const fechaObj = new Date(fechaISO);

    const anio = fechaObj.getFullYear();
    const mes = pad(fechaObj.getMonth() + 1); // Los meses van de 0 a 11, así que agregamos 1.
    const dia = pad(fechaObj.getDate());
    const horas = pad(fechaObj.getHours());
    const minutos = pad(fechaObj.getMinutes());
    const segundos = pad(fechaObj.getSeconds());

    return `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
}


export function numberWithDots(x) {
    if (x === null || x === undefined) {
        return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function mediumBlobToBase64(buffer) {
    const bytes = new Uint8Array(buffer);
    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
}
export function dataFormat(value, format) {
    if (format === 'date') {
        return dateFormat(value);
    } else if (format === 'datetime') {
        return dateTimeFormat(value);
    } else if (format === 'int') {
        return numberWithDots(value);
    } else if (format === 'mediumblob') {
        return mediumBlobToBase64(value);
    } else {
        return value;
    }
}

export function filterArray(array, filters) {
    if (Object.keys(filters).length === 0) {
        return array;
    }

    return array.filter(item => {
        for (let key in filters) {
            if (Object.prototype.hasOwnProperty.call(item, key) && String(item[key]).toLowerCase().includes(String(filters[key]).toLowerCase())) {
                continue;
            } else {
                return false;
            }
        }
        return true;
    });
}
export function sortLocal(list, orderBy, isAsc) {
    return list.sort((a, b) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return isAsc ? valueA - valueB : valueB - valueA;
        } else if (Date.parse(valueA) && Date.parse(valueB)) {
            const dateA = new Date(valueA);
            const dateB = new Date(valueB);
            return isAsc ? dateA - dateB : dateB - dateA;
        } else {
            return isAsc
                ? valueA.toString().localeCompare(valueB.toString())
                : valueB.toString().localeCompare(valueA.toString());
        }
    });
}
